import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { TEAM_MEMBERS } from "../constants/items"
import linkedinIcon from "../images/icons/linkedin.png"

const Team = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("teamTitle")} />
      <div className="container pt-nav-ghost">
        <h1 className="title members-title">{t("teamTitle")}</h1>
        <div className="members">
          {TEAM_MEMBERS.map(member => (
            <div className="member-card">
              <img className="member-img" src={member.img} alt={member.name} />
              <p className="member-name">
                {member.name}{" "}
                <a target="_blank" rel="noreferrer" href={member.linkedin}>
                  <img height={19} width={20} src={linkedinIcon} alt="linkedin" />
                </a>{" "}
              </p>
              <p className="member-title">{member.title}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Team

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
